import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui'
const baseURL = 'https://admin.yongzhengxs.cn/api/' //测试环境
export function request(config={}){
    let service = axios.create({
        baseURL: baseURL
    });
    //拦截器
    service.interceptors.request.use(function (config) {
        let token = localStorage.getItem('token')
        config.headers = {
            'token': token
        }
        // if (config.method==='post'){
        //     config.data = qs.stringify(config.data);
        // }else if (config.method==='file'){
        //     config.method="post";
        //     if (config.data && config.data instanceof Object){
        //         let params = new FormData();
        //         for (let key in config.data){
        //             params.append(key, config.data[key]);
        //         }
        //         config.data=params;
        //     }
        // }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
    //响应之后
    service.interceptors.response.use(function (response) {
        if (response.data.code == '401'){
            Message({
                message: 'token已失效请先登录',
                type: 'error',
                duration: 1000
            })
            localStorage.removeItem('token')
            localStorage.removeItem('shopId')
            router.replace('/login')
        }
        return response
    }, function (error) {
        return Promise.reject(error);
    });
    return service.request(config).then(res=>res.data)
}