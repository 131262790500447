<template>
    <div>
        <el-menu class="el-menu-vertical-demo" active-text-color="#fff" :unique-opened = "true"
            :default-active="onRoutes" :default-openeds="openeds" router>
            <el-menu-item v-for="menu in menuData" class="first-menu" :key="menu.id" :index="menu.path">
                <i v-if="menu.icon" :class="menu.icon" class="marg-R1"></i>
                <span>{{menu.name}}</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>
<script>
// import menuBar from './menuBar.vue'
export default{
    name: 'menuBar',
    props: ['menuData'],
    components: {
        // menuBar
    },
    data() {
        return {
            openeds: ['/researchData']
        }
    },
    computed: {
        onRoutes() {
            return this.$route.path.replace("/index","");
        }
    },
    created() {
        
    },
    methods: {
        // hasPermission(permission) {
        //     let str = sessionStorage.getItem('rightCodes')
        //     let rightCodes = JSON.parse(str)
        //     return rightCodes.includes(permission);
        // }
    },
    watch: {
        // $route(to,from) {
           
        // }
    }
}
</script>
<style lang="less" scoped>
    /deep/.el-menu{
        background: #121F3E;
        border-right: none;
        .el-menu-item{
            color: #838CA1;
            margin: 0 10px;
            i{
                color: #838CA1;
                font-size: 16px;
            }
        }
        .el-menu-item:hover{
            margin: 0 10px;
            background-color: #1F2E54;
        }
        .el-menu-item.is-active{
            position: relative;
            color: #fff;
            margin: 0 10px;
            background-color: #1F2E54;
            i{
                color: #fff;
            }
        }
        .el-menu-item.is-active::after{
            position: absolute;
            left: 0;
            top: 12px;
            content: "";
            width: 5px;
            height: 30px;
            opacity: 1;
            border-radius: 10px;
            background: rgba(85, 168, 253, 1);
        }
        .el-submenu{
            .el-submenu__title{
                color: #fff;
                i {
                    color: #fff;
                }
            }
            .el-menu-item{
                padding-left: 40px!important;
            }
        }
        .is-opened{
            background: #f2f2f2;
            .el-submenu__title{
                color: #106fc6;
                i {
                    color: #106fc6;
                }
            }
            .el-menu-item{
                background: #f2f2f2;
            }
            .is-active{
                background: #e1ebf7;
            }
        }
        
        .el-submenu__title:hover{
            color: #106fc6;
            i {
                color: #106fc6;
            }
        }
    }
</style>