import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../pages/layout.vue'
Vue.use(Router);
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
}
const router = new Router({
    mode:"hash",//1、hash哈希：有#号。2、history历史：没有#号
    base:process.env.BASE_URL, //自动获取根目录路径
    scrollBehavior:(to,from,position)=>{
        if(position){
            return position
        }else{
            return {x:0,y:0}
        }
    },
    routes:[
        {
            path: '/',
            name: 'Layout',
            component: Layout ,
            redirect: '/clueHall',
            children: [
                {
                    path: 'clueHall',
                    component: () => import('@/pages/clueHall'),
                    name: 'clueHall',
                    meta: {
                        title: '线索大厅'
                    }
                },{
                    path: 'clueTracking',
                    component: () => import('@/pages/clueTracking'),
                    name: 'clueTracking',
                    meta: {
                        title: '线索跟踪'
                    }
                },{
                    path: 'wallet',
                    component: () => import('@/pages/wallet'),
                    name: 'wallet',
                    meta: {
                        title: '我的钱包'
                    }
                },{
                    path: 'account',
                    component: () => import('@/pages/account'),
                    name: 'account',
                    meta: {
                        title: '账户管理'
                    }
                },{
                    path: 'transferClues',
                    component: () => import('@/pages/transferClues'),
                    name: 'transferClues',
                    meta: {
                        title: '线索转移'
                    }
                },{
                    path: 'statistics',
                    component: () => import('@/pages/statistics'),
                    name: 'statistics',
                    meta: {
                        title: '数据统计'
                    }
                },{
                    path: 'pushSet',
                    component: () => import('@/pages/pushSet'),
                    name: 'pushSet',
                    meta: {
                        title: '推送设置'
                    }
                },{
                    path: 'pushSet',
                    component: () => import('@/pages/pushSet'),
                    name: 'pushSet',
                    meta: {
                        title: '推送设置'
                    }
                },{
                    path: 'Invoicing',
                    component: () => import('@/pages/Invoicing'),
                    name: 'Invoicing',
                    meta: {
                        title: '开票'
                    }
                },{
                    path: 'service',
                    component: () => import('@/pages/service'),
                    name: 'service',
                    meta: {
                        title: '客服中心'
                    }
                },{
                    path: 'setPage',
                    component: () => import('@/pages/setPage'),
                    name: 'setPage',
                    meta: {
                        title: '设置'
                    }
                },{
                    path: 'agreement',
                    component: () => import('@/pages/agreement'),
                    name: 'agreement',
                    meta: {
                        title: '会员协议'
                    }
                },{
                    path: 'psdChange',
                    component: () => import('@/pages/psdChange'),
                    name: 'psdChange',
                    meta: {
                        title: '密码修改'
                    }
                },{
                    path: 'clueCity',
                    component: () => import('@/pages/clueCity'),
                    name: 'clueCity',
                    meta: {
                        title: '线索区域'
                    }
                },{
                    path: 'caseType',
                    component: () => import('@/pages/caseType'),
                    name: 'caseType',
                    meta: {
                        title: '案件类型'
                    }
                },{
                    path: 'lawyerDetail',
                    component: () => import('@/pages/lawyerDetail'),
                    name: 'lawyerDetail',
                    meta: {
                        title: '律师详情'
                    }
                },{
                    
                    path: 'userChange',
                    component: () => import('@/pages/userChange'),
                    name: 'userChange',
                    meta: {
                        title: '用户信息'
                    }
                },{
                    
                    path: 'message',
                    component: () => import('@/pages/message'),
                    name: 'message',
                    meta: {
                        title: '消息'
                    }
                },{
                    path: 'statisticsDetail',
                    component: () => import('@/pages/statisticsDetail'),
                    name: 'statisticsDetail',
                    meta: {
                        title: '图表筛选'
                    }
                },{
                    path: 'rechargePage',
                    component: () => import('@/pages/rechargePage'),
                    name: 'rechargePage',
                    meta: {
                        title: '充值'
                    }
                }
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/login')
        },{
            path: '/psdFind',
            name: 'psdFind',
            component: () => import('@/pages/psdFind')
        }
    ]
})
router.beforeEach((to,from,next) => {
    const token = localStorage.getItem('token')
    const shopId = localStorage.getItem('shopId')
    if(to.path == '/login' || to.path == '/psdFind') {
        next()
    } else {
        if(!token) {
            next('/login')
        } else {
            next()
        }
    }
    if(token && to.path == '/') {
        next('/')
    }
})
export default router