import Vue from 'vue'

import App from './App.vue'
import Element from 'element-ui'
import router from './router'

import store from './store'
import './assets/css/common.less'
import './assets/css/iconfont/iconfont.less'
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
// import {encryptAES, decryptAES, generatekeyAES} from '@/assets/js/commonAse'
// import Base64 from './assets/js/base64.js'
// Vue.prototype.$Base64 = Base64;
Vue.prototype.$axios = axios

// Vue.prototype.$baseURL = 'http://192.168.0.2:8085/sjtulab'
// Vue.prototype.$baseURL = 'http://10.119.1.233:8080/sjtulab' //测试
// Vue.prototype.$baseURL = 'http://10.119.9.72:8080/sjtulab'  //生产


Vue.config.productionTip = false
Vue.use(Element)

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')