<template>
    <el-container class="home-container">
        <el-container>
            <el-aside width="160px">
                <div class="logo">
                    <img src="../assets/images/pc-logo.png"/>
                </div>
                <menu-bar :menuData="menuList"></menu-bar>
            </el-aside>
            <el-main>
                <el-header>
                    <header-bar></header-bar>
                </el-header>
                <div class="main-content">
                    <transition name="fade-transform" mode="out-in">
                        <router-view />
                    </transition>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import headerBar from '@/components/headerBar.vue'
import menuBar from '@/components/menuBar.vue'
import { number } from 'echarts/lib/export';
export default{
    name: 'layout' ,
    components: {
        headerBar,
        menuBar
    },
    data() {
        return {
            activePath: '',
            menuData: [
                {
                    name: "线索大厅",
                    id: 'xsdt',
                    icon: 'iconfont icon-52shouye-1',
                    path: '/clueHall',
                    permission: [0,1],
                },
                {
                    name:"线索跟踪",
                    id: 'xsgz',
                    icon: 'iconfont icon-xunjianliebiaochaxun',
                    path: '/clueTracking',
                    permission: [0,1],
                },
                {
                    name: "我的钱包",
                    id: 'wd1b',
                    icon: 'iconfont icon-qianbao2',
                    path: '/wallet',
                    permission: [0,1],
                },
                {
                    name: "账户管理",
                    id: 'zhgl',
                    icon: 'iconfont icon-shuangrenjian',
                    path: '/account',
                    permission: [0],
                },{
                    name: "线索转移",
                    id: 'xszy',
                    icon: 'iconfont icon-jy2_liebiewancheng',
                    path: '/transferClues',
                    permission: [0],                
                },
                {
                    name: "数据统计",
                    id: 'sjtj',
                    icon: 'iconfont icon-tongji1',
                    path: '/statistics',
                    permission: [0],
                },{
                    name: "推送设置",
                    id: 'tssz',
                    icon: 'iconfont icon-xiaoxi2',
                    path: '/pushSet',
                    permission: [0,1],
                },{
                    name: "开票",
                    id: 'kp',
                    icon: 'iconfont icon-CombinedShape',
                    path: '/Invoicing',
                    permission: [0],
                },{
                    name: "客服中心",
                    id: 'kfzx',
                    icon: 'iconfont icon-kefu',
                    path: '/service',
                    permission: [0,1],
                },{
                    name: "设置",
                    id: 'sz',
                    icon: 'iconfont icon-shezhi',
                    path: '/setPage',
                    permission: [0,1],
                }
            ],
            menuList: []
        }
    },
    created() {
        
    },
    mounted() {
        let val = parseInt(this.$store.state.shopId)
        let arr = JSON.parse(JSON.stringify(this.menuData))
        this.menuList = this.getMenu(arr,val)
    },
    methods: {
        getMenu(arr,val) {
            let list = []
            list = arr.filter(item => {
                return item.permission && item.permission.indexOf(val) !== -1
            })
            return list
        }
    }
}
</script>
<style lang="less" scoped>
.home-container {
    position: relative;
    height: 100%;
    width: 100%;
    .el-header{
        position: fixed;
        top: 0;
        width: 96%;
        height: 80px;
        box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.1);
        background: #fff;
        box-sizing: border-box;
        z-index: 100;
    }
    .el-aside{
        background: #121F3E;
    }
    .el-main{
        padding: 0;
        background: #f2f2f2;
    }
    .main-content{
        margin-top: 60px;
        padding: 20px;
    }
    .logo{
        width: 160px;
        height: 64px;
        line-height: 64px;
        color: #fff;
        text-align: center;
        background: #0F182E;
        img {
            width: 100px;
        }
        span {
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            padding-left: 10px;
        }
    }
}
.el-aside::-webkit-scrollbar {
    display: none;
}
.el-aside::-o-scrollbar {
    display: none;
}
</style>