<template>
    <div class="header flex f-be">
        <div class="flex f-mi">
            <div class="">
                <img src="../assets/images/dt-pic.png"/>
            </div>
             <div class="news">
                <div :class="{ anim: animate }" @mouseenter="stop()" @mouseleave="up()">
                    <div @click="handleClick(item)" class="news_name" v-for="item in listData" :key="item.id">
                        <span>{{item.content}}</span>
                        <span class="marg-L2">{{item.create_time}}</span>
                    </div>
                </div>
            </div>
            <div class="marg-L3 marg-R3">
                <img style="vertical-align: middle;" src="../assets/images/today-icon.png"/>
                <span class="marg-L1" style="color:#1F2E54;vertical-align: middle;">今日动态：已更新{{toDay}}份</span>
            </div>
            <div class="">
                <img style="vertical-align: middle;" src="../assets/images/person-icon.png"/>
                <span class="marg-L1" style="color:#1F2E54;vertical-align: middle;">今日上线：{{person}}人</span>
            </div>
        </div>
        <div class="userInfo flex f-mi">
            <div class="flex f-mi">                
                <div class="head-box marg-R1" @click="headUp">
                    <img style="width: 30px;" :src="userInfo.profile" alt="">
                </div>
                <span>{{ userInfo.name }}-{{ userInfo.branch }}</span>
            </div>
            <div class="marg-R2">{{ userInfo.branch }}</div>
            <el-badge :is-dot="msgTotal > 0 ? true : false" class="item marg-R2">
                <i style="font-size: 20px;margin-right: 5px;" class="el-icon-bell msgIcon" @click="msgClick"></i>
            </el-badge>            
            <div class="marg-R1" @click="quitClick" style="cursor: pointer;">
                <span><i class="el-icon-switch-button" style="font-size: 20px;margin-right: 5px;"></i>退出</span>
            </div>
            <!--<el-dropdown @command="handleCommand" trigger="click">
                <span class="el-dropdown-link marg-R1">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="changePsd">密码修改</el-dropdown-item>
                    <el-dropdown-item command="exit">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>-->
        </div>
        <!-- <el-dialog title="用户信息修改" :visible.sync="dialogVis" :close-on-click-modal="false" width="400px" center @close="handleCancel">
            <div class="upload-page">
                <div class="upload-box">
                    <div class="items">
                        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                    <div class="items">
                        <p>昵称：王小二</p>
                        <p><img class="edit-icon" src="../assets/images/edit-icon.png" /></p>
                    </div>
                    <div class="items">姓名：王刚</div>
                    <div>部门：王刚</div>
                </div>
                <div class="btn-box">
                    <el-button class="btn gray" type="primary" @click="handleCancel">取消</el-button>
                    <el-button class="btn b-bg" type="primary" @click="handleSure">确认</el-button>
                </div>
            </div>
        </el-dialog> -->
    </div>
</template>
<script>
import {request} from "@/assets/js/utils/request";
export default{
    name: 'headerBar',
    data() {
        return {
            userInfo: {},
            toDay: '',
            person: '',
            listData: [],
            timer: null,
            animate: false,
            dialogVis: false,
            msgTotal: 0
        }
    },
    mounted() {
        this.scrollUp(); // 开启滚动效果
    },
    beforeDestroy() {
        this.stop();
    },
    created() {
        this.initData()
    },
    methods: {
        headUp() {
            this.$router.push('/userChange')
        },
        handleClick(item) {
            console.log(item);
        },
        // 滚动
        scrollUp() {
            this.timer = setInterval(() => {
                this.animate = true; // 向上滚动的时候需要添加动画
                setTimeout(() => {
                this.listData.push(this.listData[0]); // 将数组的第一个元素添加到数组最后一个
                this.listData.shift(); // 删除数组的第一个元素
                this.animate = false;
                }, 500);
            }, 4000);
        },
        //鼠标移上去停止
        stop() {
            clearInterval(this.timer);
        },
        //鼠标离开继续
        up() {
            this.scrollUp();
        },
        quitClick() {
            request({
                url: '/logout',
                method: 'post'
            }).then(res => {
                if(res.code == 200) {
                    this.$router.replace('/login')
                    this.$store.commit('setToken','')
                    this.$store.commit('setShopId','')
                    localStorage.removeItem('token')
                    localStorage.removeItem('shopId')
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        handleCommand(command) {
            if(command == 'changePsd') {
                this.$router.push('/psdChange')
            }else {
                this.quitClick()
            }
        },
        msgClick() {
            this.$router.push('/message')
        },
        initData() {
            // 抢单动态
            request({
                url: '/dynamic/clue',
                method: 'GET',
            }).then(res => {
                if(res.code == 200) {
                    // console.log(res,'dddd')
                    this.listData = res.data.list || []
                } else {
                    this.$message.error(res.message);
                }
            })
            // 今日动态
            request({
                url: '/dynamic',
                method: 'get'
            }).then(res => {
                if(res.code == 200) {
                    this.toDay = res.data.count
                } else {
                    this.$message.error(res.message);
                }
            })
            // 今日上线
            request({
                url: '/online',
                method: 'get'
            }).then(res => {
                if(res.code == 200) {
                    this.person = res.data.count
                } else {
                    this.$message.error(res.message);
                }
            })
            // 获取用户信息
            request({
                url: '/user/info',
                method: 'GET'
            }).then(res => {
                if(res.code == 200) {
                    this.userInfo = res.data
                } else {
                    this.$message.error(res.message);
                }
            })
            // 获取未读公告列表
            request({
                url: '/user/affiche/unread',
                method: 'GET'
            }).then(res => {
                if(res.code == 200) {
                    this.msgTotal = res.data.quantity || 0
                } else {
                    this.$message.error(res.message);
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.header{
    width: 94%;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    .logo{
        padding-top: 20px;
        line-height: 25px;
        h1{
            font-size: 28px;
            font-weight: 600;
            color: #595959;
        }
        p{
            font-size: 14px;
            color:#595959;
        }
    }
    /deep/.userInfo{
        color: #595959;
        // line-height: 60px;
        .quit-btn{
            cursor: pointer;
        }
        .head-box{
            width: 25px;
            height: 25px;
            border-radius: 50px;
            overflow: hidden;
        }
        .msgIcon{
            font-size: 20px;
            cursor: pointer;
        }
    }
    .news {
        margin: 0 20px;
        height: 30px;
        background-color: #fff;
        overflow: hidden;
    }
    .news_name {
        line-height: 30px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        transition: top 0.5s;
    }
    .anim {
        transition: all 0.5s;
        margin-top: -30px;
    }
}
</style>