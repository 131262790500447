import Vue from 'vue'
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        token: localStorage["token"] ? localStorage["token"]:"",
        shopId: localStorage.getItem('shopId') ? localStorage.getItem('shopId') : "",
        // rightCodes: sessionStorage.getItem('rightCodes') ? sessionStorage.getItem('rightCodes') : ''
    },
    mutations: {
        setToken(state,token) {
            state.token = token
        },
        setShopId(state,payload){
            state.shopId = payload
        },
        setRightCodes(state,payload) {
            state.rightCodes = payload
        }
    },
    getters: {

    },
    actions: {

    }
});

export default store